import React, { useEffect, useState } from 'react';
import './Banner.css';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [popupBanners, setPopupBanners] = useState([]);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get('https://namami-infotech.com/EvaraBackend/src/banner/get_banner.php');
        if (response.data && response.data.data) {
          const fetchedBanners = response.data.data;
          // Filter banners based on category
          setBanners(fetchedBanners.filter(banner => banner.Category === "Banner"));
          setPopupBanners(fetchedBanners.filter(banner => banner.Category === "Popup"));
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };
    fetchBanners();
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {/* Carousel for banners */}
      <div className="carousel">
        <div className="carousel-inner">
          {banners.concat(banners).map((banner, index) => (
            <div key={index} className="carousel-item">
              <img src={banner.BannerURL} alt={banner.BannerName} />
            </div>
          ))}
        </div>
      </div>

      {/* Popup for banners with Category "Popup" */}
      {showPopup && popupBanners.length > 0 && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content">
            {popupBanners.map((banner, index) => (
              <img key={index} src={banner.BannerURL} alt={banner.BannerName} className="popup-image" />
            ))}
            <button className="popup-close" onClick={closePopup}><CloseIcon/></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
