import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProfilePage from '../components/ProfilePage';


function Profile() {
  return (
    <div>
         
          <br />
          <ProfilePage/>
         
    </div>
  );
}

export default Profile;
