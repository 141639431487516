import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Grid, CardMedia, Button, Snackbar,
  CircularProgress, IconButton, TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const CartCom = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate(); // Initialize navigate for routing

  useEffect(() => {
    const fetchCartItems = async () => {
      const cart = JSON.parse(localStorage.getItem('cart')) || [];

      try {
        const medicinePromises = cart.map(({ id, quantity }) =>
          fetch(`https://namami-infotech.com/EvaraBackend/src/sku/get_sku.php?medicine_id=${id}`)
            .then(response => response.json())
            .then(data => {
              if (data.message === "Medicine details fetched successfully") {
                return { ...data.medicine, quantity };
              }
              return null;
            })
        );

        const medicineData = await Promise.all(medicinePromises);
        const medicinesInCart = medicineData.filter(Boolean);
        setCartItems(medicinesInCart);
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleQuantityChange = (medicineId, quantity) => {
    const updatedCart = cartItems.map(item => {
      if (item.medicine_id === medicineId) {
        return { ...item, quantity: Math.max(1, Math.min(quantity, 999)) };
      }
      return item;
    });
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart.map(item => ({ id: item.medicine_id, quantity: item.quantity }))));
  };

  const handleRemove = (medicineId) => {
    const updatedCart = cartItems.filter(item => item.medicine_id !== medicineId);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart.map(item => ({ id: item.medicine_id, quantity: item.quantity }))));
    setSnackbarMessage(`Removed item from cart!`);
    setSnackbarOpen(true);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + (item.quantity * item.ptr), 0).toFixed(2);
  };

  const handleCheckout = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  if (userData && userData.user_id) {
    // Navigate to the checkout component if user data is present
    navigate('/checkout');
    console.log("ok")
  } else {
    // Show a message and navigate to login if user data is missing
    setSnackbarMessage('Please log in to proceed with checkout.');
    setSnackbarOpen(true);
    navigate('/login');
  }
};


  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 1400, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>Shopping Cart</Typography>
      <div style={{ display: "flex", justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'teal', marginBottom: 2 }}
          onClick={handleCheckout} // Use the updated checkout logic
        >
          Proceed to Checkout
        </Button>
        <Box>
          <Typography variant="h6">Total: ₹{calculateTotal()}</Typography>
        </Box>
      </div>

      {cartItems.length === 0 ? (
        <Typography>Your cart is empty.</Typography>
      ) : (
        <Grid container spacing={2}>
          {cartItems.map((medicine) => (
            <Grid item xs={12} sm={6} key={medicine.medicine_id}>
              <Box
                sx={{
                  border: '1px solid #ddd',
                  padding: 2,
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minHeight: 100
                }}
              >
                <CardMedia
                  component="img"
                  height="80"
                  image={medicine.image_url}
                  alt={medicine.name}
                  sx={{ objectFit: 'contain', width: 80, marginRight: 2 }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{medicine.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {medicine.company_name || " "}
                  </Typography>
                  <Typography variant="body2">
                    MRP: ₹{medicine.mrp} | PTR: ₹{medicine.ptr}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    GST: {medicine.gst || "0"} % {/* Assuming 18% GST */}
                  </Typography>
                  <Typography variant="body2">
                    Amount: ₹{(medicine.quantity * medicine.ptr).toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => handleQuantityChange(medicine.medicine_id, medicine.quantity - 1)}
                    size="small"
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    value={medicine.quantity}
                    onChange={(e) => handleQuantityChange(medicine.medicine_id, parseInt(e.target.value))}
                    type="number"
                    inputProps={{ min: 1, max: 999, style: { textAlign: 'center' } }}
                    sx={{ width: '60px', marginX: 1 }}
                  />
                  <IconButton
                    onClick={() => handleQuantityChange(medicine.medicine_id, medicine.quantity + 1)}
                    size="small"
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemove(medicine.medicine_id)}
                    size="small"
                    sx={{ marginLeft: 'auto' }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default CartCom;
