import React from 'react';
import { Box } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';

const OnlineStoreLayout = ({ children }) => {
    return (
        <Box>
            <Navbar />
            <Box component="main" sx={{ minHeight: '80vh' }}>
                {children}
            </Box>
            <Footer />
        </Box>
    );
};

export default OnlineStoreLayout;
