import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CustomCard from '../components/CustomCard';
import { useLocation, useNavigate } from 'react-router-dom';

function SearchMedicines() {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the navigate function
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('query') || '';
  const category = queryParams.get('category') || 'All';

  const [medicines, setMedicines] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchQuery.length > 2) {
      fetchMedicines(searchQuery);
    }
  }, [searchQuery]);

  const fetchMedicines = async (query) => {
    try {
      setLoading(true);
      const response = await fetch(`https://namami-infotech.com/EvaraBackend/src/sku/list_sku.php?alpha=${query}`);
      const data = await response.json();
      setMedicines(data.medicines || []);
    } catch (error) {
      console.error('Error fetching medicines:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (medicineId) => {
    navigate(`/product/${medicineId}`); // Navigate to the product page
  };

  return (
    <>
     
      <br /><br />
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            medicines.map((medicine) => (
              <Grid item xs={12} sm={6} md={4} key={medicine.medicine_id}>
                <CustomCard
                  medicineId={medicine.medicine_id} // Ensure this matches the API response
                  image={medicine.image_url}
                  title={medicine.name}
                  description={medicine.product_details}
                  mrp={`MRP: ₹${medicine.mrp}`}
                  ptr={`PTR: ₹${medicine.ptr}`}
                  buttonText="Buy Now"
                  offer={medicine.formatted_offer}
                  onClick={() => handleCardClick(medicine.medicine_id)} // Call the handleCardClick with ID
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    
    </>
  );
}

export default SearchMedicines;
