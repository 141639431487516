import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Grid, Button } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import CustomCard from './CustomCard';
import { useNavigate } from 'react-router-dom';

const TopSelling = () => {
    const [products, setProducts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
const navigate=useNavigate()
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://namami-infotech.com/EvaraBackend/src/sku/top_selling.php');
                const data = await response.json();
                if (data && data.products) {
                    setProducts(data.products);
                } else {
                    throw new Error('No top selling products found');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const ITEMS_PER_PAGE = 5;

    const handleNext = () => {
        if (currentIndex + ITEMS_PER_PAGE < products.length) {
            setCurrentIndex(currentIndex + ITEMS_PER_PAGE);
        }
    };

    const handlePrev = () => {
        if (currentIndex - ITEMS_PER_PAGE >= 0) {
            setCurrentIndex(currentIndex - ITEMS_PER_PAGE);
        }
    };

    const handleCardClick = (medicineId) => {
        console.log('Navigating to product with ID:', medicineId);
        // You can use the `useNavigate` hook directly here if it's available
        navigate(`/product/${medicineId}`);
    };

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box
            sx={{
                background: 'linear-gradient(to bottom, rgba(255, 255, 255, -2%), rgba(255, 255, 255, 0.4) 78%), linear-gradient(0deg, #008080, #ff8c00)',
                padding: '20px',
                width: '100%',
                margin: 'auto',
                marginTop: '50px',
                position: 'relative',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    color: 'white',
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    padding: '4px 8px',
                    borderRadius: '4px',
                }}
            >
                Top Selling 
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: 2 }}>
                <IconButton onClick={handlePrev} disabled={currentIndex === 0} sx={{ color: 'white' }}>
                    <ArrowBackIos />
                </IconButton>
                <IconButton onClick={handleNext} disabled={currentIndex + ITEMS_PER_PAGE >= products.length} sx={{ color: 'white' }}>
                    <ArrowForwardIos />
                </IconButton>
                <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }}>See All</Button>
            </Box>

            <Grid container spacing={2} justifyContent="center">
                {products.slice(currentIndex, currentIndex + ITEMS_PER_PAGE).map((product) => (
                    <Grid item xs={12} sm={6} md={2.4} key={product.medicine_id}>
                        <CustomCard
                            medicineId={product.medicine_id} // Ensure this matches the API response
                            image={product.image_url}
                            title={product.name}
                            description={`Company: ${product.company_name}`}
                            mrp={`${product.mrp}`}
                            ptr={`${product.ptr}`}
                            buttonText="View Details"
                            onButtonClick={() => handleCardClick(product.medicine_id)} // Pass the ID to the handler
                            offer={product.formatted_offer}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TopSelling;
