// TopOffers.jsx
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import CustomCard from '../components/CustomCard';

const TopOffers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await fetch('https://namami-infotech.com/EvaraBackend/src/sku/offers_sku.php');
        const data = await response.json();
        if (data && data.medicines) {
          setOffers(data.medicines);
        } else {
          throw new Error('No offers found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>All Trending Offers</Typography>
      <Grid container spacing={2}>
        {offers.map((offer) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={offer.medicine_id}>
            <CustomCard
              medicineId={offer.medicine_id}
              image={offer.image_url}
              title={offer.name}
              description={`Company: ${offer.company_name}`}
              mrp={`MRP: ₹${offer.mrp}`}
              ptr={`PTR: ₹${offer.ptr}`}
              buttonText="View Details"
              offer={offer.formatted_offer}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TopOffers;
