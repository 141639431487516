import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container, TextField, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Switch, Pagination, Typography, Box, Button,
    IconButton
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';

const UsersList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState('all');
    const [editingDiscountUserId, setEditingDiscountUserId] = useState(null); // To track which user’s discount is being edited
    const [discountValue, setDiscountValue] = useState(''); // Store discount value while editing
    const itemsPerPage = 10;

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://namami-infotech.com/EvaraBackend/src/user/list_user.php');
            if (response.data.message === 'User list fetched successfully') {
                setUsers(response.data.users);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        const filterAndPaginateUsers = () => {
            let filtered = users.filter(user =>
                user && (
                    (statusFilter === 'all' || user.status === statusFilter) &&
                    (user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        user.email.toLowerCase().includes(searchTerm.toLowerCase()))
                )
            );

            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const paginated = filtered.slice(startIndex, endIndex);

            setFilteredUsers(paginated);
        };

        filterAndPaginateUsers();
    }, [searchTerm, users, currentPage, statusFilter]);

    const updateUserStatus = async (userEmail, newStatus) => {
        try {
            const response = await axios.post('https://namami-infotech.com/EvaraBackend/src/user/approve_user.php', { 
                email: userEmail, 
                status: newStatus 
            });

            if (response.data.success) {
                setUsers(prevUsers =>
                    prevUsers.map(user =>
                        user.email === userEmail
                            ? { ...user, status: newStatus }
                            : user
                    )
                );
                fetchUsers();
            } else {
                console.error('Failed to update user status:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    const handleDiscountChange = async (userEmail) => {
        try {
            await axios.post('https://namami-infotech.com/EvaraBackend/src/user/add_discount.php', {
                email: userEmail,
                discount: discountValue
            });

            setUsers(prevUsers =>
                prevUsers.map(user =>
                    user.email === userEmail
                        ? { ...user, discount: discountValue }
                        : user
                )
            );

            // Reset editing state after saving
            setEditingDiscountUserId(null);
            setDiscountValue('');
        } catch (error) {
            console.error('Error updating discount:', error);
        }
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleStatusFilterChange = (status) => {
        setStatusFilter(status);
        setCurrentPage(1);
    };

    return (
        <Container>
            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", marginBottom: "20px" }}>
                <Typography variant="h4" gutterBottom style={{ color: "orange", fontSize: "30px" }}>CLIENTS LIST</Typography>
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <TextField
                        label="SEARCH USERS..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        sx={{ mb: 1, '& .MuiOutlinedInput-root': { borderRadius: '30px', maxHeight: "40px", width: "300px" } }}
                    />
                    <Button
                        variant={statusFilter === 'active' ? 'contained' : 'outlined'}
                        onClick={() => handleStatusFilterChange('active')}
                        color="primary"
                    >
                        ACTIVE
                    </Button>
                    <Button
                        variant={statusFilter === 'pending' ? 'contained' : 'outlined'}
                        onClick={() => handleStatusFilterChange('pending')}
                        color="secondary"
                    >
                        PENDING
                    </Button>
                    <Button
                        variant={statusFilter === 'all' ? 'contained' : 'outlined'}
                        onClick={() => handleStatusFilterChange('all')}
                    >
                        ALL
                    </Button>
                </div>
            </div>

            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>USERNAME</TableCell>
                            <TableCell>EMAIL</TableCell>
                            <TableCell>MOBILE</TableCell>
                            <TableCell>USER ROLE</TableCell>
                            <TableCell>DISCOUNT</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell>ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map(user => (
                               <TableRow key={user.user_id}>
    <TableCell>{user.user_id}</TableCell>
    <TableCell><Link to={`/admin/client/${user.user_id}`}>{user.username.toUpperCase()}</Link></TableCell>
    <TableCell>{user.email.toUpperCase()}</TableCell>
    <TableCell>{user.phone_number.toUpperCase()}</TableCell>
    <TableCell>{user.user_type.toUpperCase()}</TableCell>
    <TableCell>
        {editingDiscountUserId === user.user_id ? (
            <Box display="flex" alignItems="center">
                <TextField
                    type="number"
                    label="DISCOUNT"
                    value={discountValue}
                    onChange={(e) => setDiscountValue(e.target.value)}
                    InputProps={{ inputProps: { step: 0.1, min: 0, max: 100 } }}
                />
                <IconButton
                    color="primary"
                    onClick={() => handleDiscountChange(user.email)}
                >
                    <CheckIcon />
                </IconButton>
            </Box>
        ) : (
            <Typography
                onClick={() => {
                    setEditingDiscountUserId(user.user_id);
                    setDiscountValue(user.discount || '');
                }}
                style={{ cursor: 'pointer' }}
            >
                {user.discount ? user.discount.toUpperCase() : "ENTER HERE"}
            </Typography>
        )}
    </TableCell>
    <TableCell>{user.status.toUpperCase()}</TableCell>
    <TableCell>
        <Switch
            checked={user.status === 'active'}
            onChange={() => updateUserStatus(user.email, user.status === 'active' ? 'inactive' : 'active')}
            color="primary"
        />
    </TableCell>
</TableRow>

                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} align="center">NO USERS FOUND</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box display="flex" justifyContent="center" marginTop="30px">
                <Pagination
                    count={Math.ceil(users.filter(user => user).length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Container>
    );
};

export default UsersList;
