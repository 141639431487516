import React, { useState } from 'react'
import { Chart } from 'react-google-charts'
import { colors } from '../../constants'
import './style.scss'

const OrderStatusChart = ({ orderStatusData }) => {
    const [timePeriod, setTimePeriod] = useState('today')

    const getDataForPeriod = (period) => {
    if (orderStatusData) {
        const acceptedOrders = Number(orderStatusData[period].accepted_orders);
        const rejectedOrders = Number(orderStatusData[period].rejected_orders);
        const deliveredOrders = Number(orderStatusData[period].delivered_orders);
        const totalOrders = acceptedOrders + rejectedOrders + deliveredOrders;

        return [
            ['Order Type', 'Number of Orders'],
            ['Accepted Orders', acceptedOrders],
            ['Rejected Orders', rejectedOrders],
            ['Delivered Orders', deliveredOrders],
            ['Pending Orders', totalOrders - (acceptedOrders + rejectedOrders + deliveredOrders)]
        ];
    }
    return [['Order Type', 'Number of Orders'], ['No Data', 0]];
};


    const chartData = getDataForPeriod(timePeriod)

    const chartOptions = {
        pieHole: 0.4,
        is3D: true,
        colors: [
            colors.purple,    // Total Orders
            colors.green,     // Accepted Orders
            colors.red,       // Rejected Orders
            colors.orange     // Delivered Orders
        ],
        pieSliceText: 'value', // Display the number of orders on the chart slices
    }


    return (
        <>
            <div className="title mb">
                Orders Status
            </div>
            <div className="mb">
                <button
                    onClick={() => setTimePeriod('today')}
                    className={`btn ${timePeriod === 'today' ? 'btn-active' : ''}`}
                >
                    Today
                </button>
                <button
                    onClick={() => setTimePeriod('last_7_days')}
                    className={`btn ${timePeriod === 'last_7_days' ? 'btn-active' : ''}`}
                >
                    Last 7 Days
                </button>
                <button
                    onClick={() => setTimePeriod('last_30_days')}
                    className={`btn ${timePeriod === 'last_30_days' ? 'btn-active' : ''}`}
                >
                    Last 30 Days
                </button>
            </div>
            <div>
                <Chart
                    chartType="PieChart"
                    data={chartData}
                    options={chartOptions}
                    width={"100%"}
                    height={"245px"}
                />
            </div>
        </>
    )
}

export default OrderStatusChart
