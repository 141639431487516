import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import OrderHistory from '../components/OrderHistory';


function Orders() {
  return (
    <div>
        
          <br />
        <OrderHistory/>
        
          
    </div>
  );
}

export default Orders;
