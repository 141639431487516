import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography, Alert, Divider, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../online-store/components/Navbar';
import Footer from '../online-store/components/Footer';
import logo1 from "../assets/images/logo1.png";
import playStoreImage from '../online-store/playstore.png'; // Adjust path as necessary
import appStoreImage from '../online-store/appstore.png'; // Adjust path as necessary
import CloseIcon from '@mui/icons-material/Close';

const LoginForm = ({ setIsAuthenticated }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false); // State for modal visibility
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setLoading(true);

        if (phoneNumber.trim() === '' || password.trim() === '') {
            setError('Both fields are required.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post('https://namami-infotech.com/EvaraBackend/src/auth/login.php', {
                phone_number: phoneNumber,
                password: password,
                companyname: "EVARA"
            });

            if (response.data.token) {
                const userRole = response.data.userData.user_type;
                localStorage.setItem('userData', JSON.stringify(response.data.userData));
                if (userRole === 'dealer') {
                    navigate('/');
                } else {
                    localStorage.setItem('authToken', response.data.token);
                    localStorage.setItem('userRole', userRole);
                    localStorage.setItem('userName', response.data.userData.username);
                    setIsAuthenticated(true);
                    navigate('/admin');
                }
            } else {
                setError('Invalid phone number or password.');
            }
        } catch (err) {
            console.error('Login error:', err);
            setError('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Navbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: { md: '80vh' },
                        boxShadow: 3,
                        borderRadius: 2,
                        p: 2,
                        backgroundColor: '#fff'
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            display: { xs: 'none', md: 'block' },
                            textAlign: 'center'
                        }}
                    >
                        <img
                            src={logo1}
                            alt="Login Illustration"
                            style={{ width: '100%', maxWidth: '400px' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1, p: 2 }}>
                        <Typography variant="h5" gutterBottom style={{ color: "orange", textAlign: 'center' }}>
                            Sign In / Sign Up
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>
                            Sign up or Sign in to access your orders, special offers, health tips, and more!
                        </Typography>
                        {error && <Alert severity="error">{error}</Alert>}
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                            <TextField
                                label="Phone Number"
                                type="tel"
                                fullWidth
                                required
                                margin="normal"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                inputProps={{ maxLength: 10 }}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                fullWidth
                                required
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                sx={{ mt: 3, mb: 2, color: "#fff", backgroundColor: "#008080" }}
                            >
                                {loading ? 'Logging in...' : 'Login'}
                            </Button>
                            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', marginBottom: '20px', color: 'teal' }}>
                                Do not have Account? <Link to="#" onClick={handleOpenModal} style={{ color: "orange" }}>Sign up Here</Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>

            {/* Sign-Up Modal */}
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle sx={{display:"flex",justifyContent:"space-between"}}>
                    <div>
                        Register Yourself
                        </div>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseModal}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        To register, download the application from the Play Store or App Store.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                        <a href="https://play.google.com/store/apps/details?id=com.namami.evara_app" target="_blank" rel="noopener noreferrer">
                            <img src={playStoreImage} alt="Download from Play Store" style={{ width: '100px', height: 'auto' }} />
                        </a>
                        <a href="https://apps.apple.com/" target="_blank" rel="noopener noreferrer">
                            <img src={appStoreImage} alt="Download from App Store" style={{ width: '100px', height: 'auto' }} />
                        </a>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Footer />
        </>
    );
};

export default LoginForm;
