import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, CardMedia, Button, Snackbar, CircularProgress } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CartCom from '../components/CartCom';

const Cart = () => {
  

 
    return (
        <>
           <br />
              <CartCom/>
            
            </>
  );
};

export default Cart;
