import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Box, Card, CardContent, Typography, Avatar, Grid,
  Button, AppBar, Toolbar, Divider
} from '@mui/material';

const ProfilePage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUser(JSON.parse(storedUserData));
    }
  }, []);

  const isUserDataAvailable = user && user.username;

 const handleLogout = () => {
  localStorage.removeItem('userData'); // Only remove the userData
  navigate('/');
};


  return (
    <Box>
      <AppBar
  position="static"
  sx={{
    backgroundColor: "teal",
    maxWidth: 700, // Set the desired width here
    margin: '0 auto', // Center the AppBar
    borderRadius: 1, // Optional: add some rounding for a softer look
    boxShadow: 2 // Optional: add shadow for better visibility
  }}
>
  <Toolbar>
    <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
      Dealer Profile
    </Typography>
  </Toolbar>
</AppBar>


      <Container maxWidth="md" sx={{ marginTop: 4 }}>
        {isUserDataAvailable ? (
          <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
            <CardContent>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={3}>
                  <Avatar
                    alt={user.username}
                    src={user.username || 'https://via.placeholder.com/150'}
                    sx={{ width: 120, height: 120, border: '3px solid teal' }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    {user.username}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ textTransform: 'uppercase', color: 'teal', fontWeight: 'bold' }}>
                    {user.user_type}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                    {user.email}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ marginY: 2 }} />

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" sx={{ color: "#004d40", fontWeight: 500 }}>Contact Information</Typography>
                  <Typography variant="body2">Phone: {user.phone_number || 'N/A'}</Typography>
                  <Typography variant="body2">Delivery Address: {user.delivery_address || 'N/A'}</Typography>
                  <Typography variant="body2">Mailing Address: {user.mailing_address || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant="h6" sx={{ color: "#004d40", fontWeight: 500 }}>Identification Details</Typography>
                  <Typography variant="body2">Aadhar No: {user.aadhar_no || 'N/A'}</Typography>
                  <Typography variant="body2">PAN No: {user.pan_no || 'N/A'}</Typography>
                  <Typography variant="body2">GST No: {user.gst_no || 'N/A'}</Typography>
                  <Typography variant="body2">Driving License Expiry: {user.dl_expire_date || 'N/A'}</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography variant="h6" sx={{ color: "#004d40", fontWeight: 500 }}>Business Information</Typography>
                  <Typography variant="body2">Category: {user.category || 'N/A'}</Typography>
                  <Typography variant="body2">Discount: {user.discount || 'N/A'}%</Typography>
                  <Typography variant="body2">Trade License: {user.trade_lic || 'N/A'}</Typography>
                </Grid>
              </Grid>

              <Divider sx={{ marginY: 2 }} />

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button variant="contained" color="primary" onClick={handleLogout} sx={{ backgroundColor: 'teal' }}>
                  Logout
                </Button>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Box sx={{ textAlign: 'center', marginTop: 4 }}>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              Please log in.
            </Typography>
            <Button variant="contained" sx={{backgroundColor:'#32AEB1'}} onClick={() => navigate('/login')}>
              Go to Login Page
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ProfilePage;
