import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import { Visibility, RotateLeft, RotateRight } from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from '../auth/AuthContext';
import VisitMap from './VisitMap';

function MapPage() {
    const [visits, setVisits] = useState([]);
    const { user } = useAuth();
    const [mapCenter, setMapCenter] = useState({ lat: 20.5937, lng: 78.9629 });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [markers, setMarkers] = useState([]);
    const [directions, setDirections] = useState(null);
    const [distances, setDistances] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmpId, setSelectedEmpId] = useState(user.role === 'HR' ? '' : user.emp_id);
    const [showMap, setShowMap] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
        const [noData, setNoData] = useState(false);

    // Popup states for image
    const [openDialog, setOpenDialog] = useState(false);
    const [imageData, setImageData] = useState('');
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        if (user.role === 'HR') {
            const fetchEmployees = async () => {
                try {
                    const response = await axios.get('https://namami-infotech.com/EvaraBackend/SalesSmile/src/employee/list_employee.php');
                    
                    if (response.data.success) {
                        setEmployees(response.data.data);
                    } else {
                        console.log('Failed to fetch employee list');
                    }
                } catch (error) {
                    console.log('Error fetching employee list:', error.message);
                }
            };
            fetchEmployees();
        }
    }, [user.role]);
useEffect(() => {
    // Calculate the center of all markers if there are any
    if (markers.length > 0) {
        const avgLat = markers.reduce((sum, marker) => sum + marker.lat, 0) / markers.length;
        const avgLng = markers.reduce((sum, marker) => sum + marker.lng, 0) / markers.length;
        setMapCenter({ lat: avgLat, lng: avgLng });
    }
}, [markers]);

     const fetchVisits = async () => {
        setLoading(true);
        setNoData(false);
        try {
            const formattedDate = selectedDate.toISOString().substr(0, 10);
            const url = `https://namami-infotech.com/EvaraBackend/SalesSmile/src/visit/view_visit.php?empId=${selectedEmpId}&date=${formattedDate}`;
            
            const response = await axios.get(url);
            if (response.data.success && response.data.data.length > 0) {
                const visitData = response.data.data;
                console.log("Visit Data:", visitData);
                
                const markerData = visitData.map((visit, index) => {
                    const [lat, lng] = visit.VisitLatLong.split(',').map(Number);
                    return {
                        lat,
                        lng,
                        label: `${index + 1}`,  // Display visit count starting from 1
                        companyName: visit.CompanyName,
                        dealerName: visit.DealerName,
                        visitTime: new Date(visit.VisitTime).toLocaleString(),
                        mobileNo: visit.MobileNo,
                    };
                });

                setVisits(visitData);
                setMarkers(markerData);
            } else {
                setVisits([]);
                setNoData(true);
            }
        } catch (err) {
            console.error('Error fetching visits:', err);
            setNoData(true);
        }
        setLoading(false);
    };

    const handleDateChange = (event) => {
        setSelectedDate(new Date(event.target.value));
    };

   
const handleViewImage = async (visitId) => {
        try {
            const response = await axios.get(`https://namami-infotech.com/EvaraBackend/SalesSmile/src/visit/view_one_visit.php?visit_id=${visitId}`);
            const base64Data = response.data.data[0].Attachment;
            setImageData(`data:image/jpeg;base64,${base64Data}`);
            setRotation(0);
            setOpenDialog(true);

            // Fetch and set address
            const address = await fetchAddressFromLatLong(response.data.data[0].VisitLatLong);
            setAddress(address);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };
    const handleRotateLeft = () => {
        setRotation((prevRotation) => prevRotation - 90);
    };

    const handleRotateRight = () => {
        setRotation((prevRotation) => prevRotation + 90);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

     const fetchAddressFromLatLong = async (latLong) => {
        const [lat, lng] = latLong.split(',').map(Number);
        const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;

        try {
            const response = await axios.get(nominatimUrl);
            if (response.data && response.data.display_name) {
                return response.data.display_name;
            } else {
                return "Address not found";
            }
        } catch (error) {
            console.error("Error fetching address from Nominatim:", error);
            return "Address not found";
        }
    };

    return (
        <>
            <div style={{display:'flex', gap:'10px'}}>
            {user.role === 'HR' && (
                <FormControl variant="outlined" sx={{ mb: 2, width: "300px" }}>
                    <InputLabel id="select-empId-label">Select Employee</InputLabel>
                    <Select
                        labelId="select-empId-label"
                        value={selectedEmpId}
                        onChange={(e) => setSelectedEmpId(e.target.value)}
                        label="Select Employee"
                    >
                        {employees.map(employee => (
                            <MenuItem key={employee.EmpId} value={employee.EmpId}>
                                {employee.Name} ({employee.EmpId})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            
            <TextField
                type="date"
                value={selectedDate.toISOString().substr(0, 10)}
                onChange={handleDateChange}
                variant="outlined"
            />
            <Button
                variant="contained"
                style={{ marginBottom: 20, backgroundColor: "teal", color: "white" }}
                onClick={fetchVisits}
            >
                Go
            </Button>

            </div>
          
            

            
                <VisitMap
                    markers={markers}
                    mapCenter={mapCenter}
                    directions={directions}
                    distances={distances}
                />
           

         
        </>
    );
}

export default MapPage;
