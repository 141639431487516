import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import TrendingOffers from '../components/TrendingOffers';
import TopSelling from '../components/TopSelling';
import DetailPage from '../components/DetailPage';
import { useParams } from 'react-router-dom';

function Product() {
  return (
    <div>
         
          <br /><br />
          <DetailPage />
          <TopSelling/>
         
          
    </div>
  );
}

export default Product;
