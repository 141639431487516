import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CssBaseline from '@mui/material/CssBaseline';
import Support from '../components/Support';
const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        background: {
            default: '#f4f6f8',
        },
    },
});
function SupportPage() {
    
  return (
    <div>
         
          <br />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Support />
        </ThemeProvider>
         
          
    </div>
  );
}

export default SupportPage;
