import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      navigate('/login');
    } else {
      fetchOrders(userData.user_id);
    }
  }, [navigate]);

  const fetchOrders = async (userId) => {
    try {
      const response = await axios.get(
        `https://namami-infotech.com/EvaraBackend/src/order/get_orders.php?user_id=${userId}`
      );
      if (response.data.success) {
        setOrders(response.data.orders);
      } else {
        console.log('Failed to fetch orders.');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  return (
    <Box p={2}>
      <Typography variant="h4" align="center" sx={{ color: "orange" }} gutterBottom>
        Orders History
      </Typography>
      <Grid container spacing={2}>
        {orders.length > 0 ? (
          orders.map((order) => (
            <Grid item xs={12} sm={6} key={order.orderId}>
              <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" sx={{ color: "teal" }}>
                      Order ID: {order.orderId}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Status: {order.status}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Total Amount: ₹{order.total_amount}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Order Date: {order.order_date}
                    </Typography>
                  </Box>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-content-${order.orderId}`}
                      id={`panel-header-${order.orderId}`}
                    >
                      <Typography sx={{ color: "orange" }}>View Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" justifyContent="space-between" mb={2}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Delivery Date: {order.reachby_date}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Remark: {order.remark || 'No remarks'}
                        </Typography>
                      </Box>

                      <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Proof of Delivery (POD):
                      </Typography>
                      {order.pod_image ? (
                        <Box
                          component="img"
                          src={`data:image/png;base64,${order.pod_image}`}
                          alt="Proof of Delivery"
                          sx={{ width: '100%', maxHeight: '200px', objectFit: 'contain', borderRadius: 2, mb: 2 }}
                        />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No POD available
                        </Typography>
                      )}

                      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                        Product Details
                      </Typography>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Buy Quantity</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Offer Quantity</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Net Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.products.map((product) => (
                            <TableRow key={product.product_id}>
                              <TableCell>{product.product_name}</TableCell>
                              <TableCell>{product.buy_quantity}</TableCell>
                              <TableCell>{product.offer_quantity}</TableCell>
                              <TableCell>{product.net_quantity}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              No orders found.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OrderHistory;
