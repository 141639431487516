import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import TrendingOffers from '../components/TrendingOffers';
import TopSelling from '../components/TopSelling';
import CoreValues from '../components/CoreValues';

function Home() {
  return (
    <div>
          <br /> 
          <Banner />
      <TrendingOffers />
      <CoreValues/>
          <TopSelling/>
    </div>
  );
}

export default Home;
