import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Grid, Button } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import CustomCard from './CustomCard';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const TrendingOffers = () => {
  const [offers, setOffers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const navigate = useNavigate(); // Initialize the navigate function

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await fetch('https://namami-infotech.com/EvaraBackend/src/sku/offers_sku.php');
        const data = await response.json();
        if (data && data.medicines) {
          setOffers(data.medicines);
        } else {
          throw new Error('No offers found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  const ITEMS_PER_PAGE = 5; // Number of cards to show at a time

  const handleNext = () => {
    if (currentIndex + ITEMS_PER_PAGE < offers.length) {
      setCurrentIndex(currentIndex + ITEMS_PER_PAGE);
    }
  };

  const handlePrev = () => {
    if (currentIndex - ITEMS_PER_PAGE >= 0) {
      setCurrentIndex(currentIndex - ITEMS_PER_PAGE);
    }
  };

  const handleCardClick = (medicineId) => {
    console.log('Navigating to offer with ID:', medicineId);
    navigate(`/product/${medicineId}`); // Navigate to the product page
  };

  // Handle navigation to the TopOffers component
  const handleSeeAll = () => {
    navigate('/top-offers'); // Navigate to the all offers page
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box
      sx={{
        background: 'linear-gradient(to bottom, rgba(255, 255, 255, -2%), rgba(255, 255, 255, 0.4) 78%), linear-gradient(180deg, #008080, #ff8c00)',
        padding: '20px',
        width: '100%',
        margin: 'auto',
        marginTop: '50px',
        position: 'relative',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: 'white',
          position: 'absolute',
          top: 16,
          left: 16,
          padding: '4px 8px',
          borderRadius: '4px',
        }}
      >
        Top Offers
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: 2 }}>
        <IconButton onClick={handlePrev} disabled={currentIndex === 0} sx={{ color: 'white' }}>
          <ArrowBackIos />
        </IconButton>
        <IconButton onClick={handleNext} disabled={currentIndex + ITEMS_PER_PAGE >= offers.length} sx={{ color: 'white' }}>
          <ArrowForwardIos />
        </IconButton>
        <Button variant="outlined" sx={{ color: 'white', borderColor: 'white' }} onClick={handleSeeAll}>See All</Button>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {offers.slice(currentIndex, currentIndex + ITEMS_PER_PAGE).map((offer) => (
          <Grid item xs={12} sm={6} md={2.4} key={offer.medicine_id}>
            <CustomCard
              medicineId={offer.medicine_id} // Ensure this matches the API response
              image={offer.image_url}
              title={offer.name}
              description={`Company: ${offer.company_name}`}
              mrp={`${offer.mrp}`}
              ptr={`${offer.ptr}`}
              buttonText="View Details"
              onButtonClick={() => handleCardClick(offer.medicine_id)} // Call the handleCardClick with ID
              offer={offer.formatted_offer}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TrendingOffers;
