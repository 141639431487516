import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import integrity from "../integrity.png"
import quality from "../quality.png"
import reliability from "../reliability.png"
import innovation from "../innovation.png"
import cf from "../cf.png"
import collab from "../collab.png"

const coreValues = [
  { 
    title: "Integrity", 
    description: "We uphold the highest standards of honesty, ethics and transparency in all our dealings.",
    icon: integrity
  },
  { 
    title: "Quality", 
    description: "We are committed to delivering pharmaceutical products & services that meet or exceed the most stringent quality standards.",
    icon: quality
  },
  { 
    title: "Reliability", 
    description: "Our customers can rely on us for consistent, timely, and accurate delivery of their pharmaceutical needs.",
    icon: reliability
  },
  { 
    title: "Innovation", 
    description: "We embrace innovation in our operations, processes, and technologies to continuously improve our services and offerings.",
    icon: innovation
  },
  { 
    title: "Customer Focus", 
    description: "We prioritize understanding and fulfilling the needs of our customers, providing personalized solutions and exceptional service.",
    icon: cf
  },
  { 
    title: "Collaboration", 
    description: "We believe in the power of collab and actively seek partnerships with customers, suppliers, and stakeholders to achieve mutual success.",
    icon: collab
  },
];

const CoreValues = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f4f4f4' }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Our Core Values
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {coreValues.map((value, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box 
              sx={{ 
                padding: '20px', 
                backgroundColor: '#32AEB1', 
                borderRadius: '8px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                textAlign: 'center',
                minHeight: '180px',
              }}
            >
              <Box 
                component="img"
                src={value.icon} 
                alt={`${value.title} icon`}
                sx={{ 
                  width: 40, 
                  height: 40, 
                  mb: 2 
                }} 
              />
              <Typography variant="h6" fontWeight="bold" sx={{ color: 'orange' }}>
                {value.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                {value.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoreValues;
