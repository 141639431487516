import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PlayStoreImage from '../playstore.png'; // Replace with the correct path
import AppStoreImage from '../appstore.png'; // Replace with the correct path
import store from '../store.png'
import location from '../location.png'
import user from '../user.png'
import Logo from '../../assets/images/logo1.png';

const FooterTop = () => {
  return (
    <Box sx={{  padding: '20px', textAlign: 'center', backgroundColor:'#32AEB1' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, backgroundColor:'#32AEB1' }}>
        <img
          src={Logo}
          alt="EvaraLifeSciences.com"
          style={{ width: '100px' }}
        />
        <Typography variant="body1" sx={{ maxWidth: '600px',color:'white' }}>
          EvaraLifeSciences.com is leading wholesale distribution of pharmaceutical products serving healthcare providers, pharmacies, hospitals and clinics in India.
        </Typography>
      </Box>
    </Box>
  );
};

const StoreInfo = () => {
  return (
    <Box sx={{ backgroundColor: '#f2f2f2', padding: '30px 0' }}>
      <Grid container spacing={3} justifyContent="center" textAlign="center">
        <Grid item xs={12} sm={4}>
          <img src={store} alt="Offline Stores" style={{ width: '40px' }} />
          <Typography variant="h6" sx={{ mt: 1 }}>100+</Typography>
          <Typography>Pharma Companies Products</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img src={location} alt="PAN India delivery" style={{ width: '40px' }} />
          <Typography variant="h6" sx={{ mt: 1 }}>5000+</Typography>
          <Typography>Range of Products</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <img src={user} alt="Happy Customers" style={{ width: '40px' }} />
          <Typography variant="h6" sx={{ mt: 1 }}>500+</Typography>
          <Typography>Happy Retailers</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const Footer = () => {
  return (
    <>
     {/* <hr style={{ maxWidth: '1000px', margin: 'auto' }} /> */}

      <StoreInfo /> {/* Add StoreInfo component */}
     {/* <hr style={{ maxWidth: '1000px', margin: 'auto' }} /> */}

      <FooterTop /> {/* Add FooterTop component above StoreInfo */}
      {/* <hr style={{ maxWidth: '1000px', margin: 'auto' }} /> */}

      <Box sx={{ backgroundColor: '#32AEB1', color: 'white', padding: '20px 20px', marginTop: '0px' }}>
        <Grid container spacing={3} justifyContent="center">
          {/* Column 1 */}
          <Grid item xs={12} sm={4} textAlign="center">
            <Typography variant="h6" sx={{ mb: 2, color: "#013567" }}>Customer Service</Typography>
            <Typography component="a" href="/support" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>Contact Us</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>Returns & Refunds</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>Shipping Information</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>FAQs</Typography>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} sm={4} textAlign="center">
            <Typography variant="h6" sx={{ mb: 2, color: "#013567" }}>About Us</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>Our Story</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>Careers</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>Privacy Policy</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>Terms of Service</Typography>
          </Grid>

          {/* Column 3 */}
          <Grid item xs={12} sm={4} textAlign="center">
            <Typography variant="h6" sx={{ mb: 2, color: "#013567" }}>Follow Us</Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>
              <FacebookIcon sx={{ mr: 1 }} /> Facebook
            </Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>
              <TwitterIcon sx={{ mr: 1 }} /> Twitter
            </Typography>
            <Typography component="a" href="#" sx={{ display: 'block', mb: 1, color: 'white', textDecoration: 'none' }}>
              <InstagramIcon sx={{ mr: 1 }} /> Instagram
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Typography variant="h6" sx={{ color: "#013567" }}>Download Our App</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2 }}>
            <a href="https://play.google.com/store/apps/details?id=com.namami.evara_app">
              <img src={PlayStoreImage} alt="Google Play" style={{ width: '130px' }} />
            </a>
            <a href="https://apps.apple.com/us/app/evara/id6736901161">
              <img src={AppStoreImage} alt="App Store" style={{ width: '130px' }} />
            </a>
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 3, pt: 2, borderTop: '1px solid #ccc' }}>
          <Typography variant="body2" color="white">
            © {new Date().getFullYear()} EVARA LIFE SCIENCES. All Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
