import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Badge,
  Paper,
  CircularProgress,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo1.png';
import debounce from 'lodash.debounce';

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  boxShadow: '0 1px 4px rgba(0,0,0,0.2)',
  width: '100%',
  maxWidth: 500,
  borderRadius: '10px',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  position: 'relative',
  height: '40px',
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0.5, 1),
  backgroundColor: 'white',
  borderRadius: '10px',
  border: 'none',
  fontSize: '0.875rem',
  '&:focus': {
    outline: 'none',
  },
}));

const IconButtonStyle = {
  p: 0.5,
  color: 'teal',
  borderRadius: '0',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
};

const SuggestionsContainer = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  zIndex: 10,
  backgroundColor: '#fff',
  boxShadow: theme.shadows[3],
  maxHeight: 300,
  overflowY: 'auto',
  width: '100%',
  marginTop: theme.spacing(1),
}));

function Navbar() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [username, setUsername] = useState(null);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };

  useEffect(() => {
    if (query.length > 2) {
      debouncedFetchSuggestions(query);
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const debouncedFetchSuggestions = debounce(async (query) => {
    try {
      setLoading(true);
      const response = await fetch(`https://namami-infotech.com/EvaraBackend/src/sku/list_sku.php?alpha=${query}`);
      const data = await response.json();
      const filteredSuggestions = data.medicines ? data.medicines.filter(medicine => medicine.status === 1) : [];
      setSuggestions(filteredSuggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleSearch = () => {
    if (!query) return;
    navigate(`/search?query=${query}`);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (suggestion) => {
    navigate(`/product/${suggestion.medicine_id}`);
    setQuery('');
    setShowSuggestions(false);
  };

  const updateCartItemCount = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const totalItems = cart.reduce((total, item) => total + item.quantity, 0);
    setCartItemCount(totalItems);
  };

  useEffect(() => {
    updateCartItemCount();
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      setUsername(userData.username);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      updateCartItemCount();
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData) {
        setUsername(userData.username);
      } else {
        setUsername(null);
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const drawer = (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/support">
          <ListItemText primary="Support" />
        </ListItem>
        <ListItem button component={Link} to="/orders">
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem button onClick={handleSearchToggle}>
          <ListItemText primary="Search" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#32AEB1', top: 0, zIndex: 1100 }}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Link to="/">
              <img src={Logo} alt="Logo" style={{ width: 70, marginLeft: 0 }} />
            </Link>
          </Box>

          {!isMobile && (
            <SearchContainer>
              <SearchInput
                placeholder="Search for medicines & wellness products..."
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  setShowSuggestions(true);
                }}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                inputProps={{ 'aria-label': 'search' }}
              />
              <IconButton type="button" sx={IconButtonStyle} onClick={handleSearch}>
                {loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
              </IconButton>
              {showSuggestions && suggestions.length > 0 && (
                <SuggestionsContainer>
                  <List>
                    {suggestions.map((suggestion) => (
                      <ListItem key={suggestion.medicine_id} disablePadding>
                        <ListItem button onClick={() => handleSuggestionClick(suggestion)} sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={suggestion.image_url}
                            alt={suggestion.name}
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: '4px',
                              marginRight: '8px',
                            }}
                          />
                          <ListItemText primary={suggestion.name} />
                        </ListItem>
                      </ListItem>
                    ))}
                  </List>
                </SuggestionsContainer>
              )}
            </SearchContainer>
          )}

          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', marginLeft: 2 }}>
            <Link to="/" style={{ marginRight: "20px" }}>
              <IconButton aria-label="home" sx={{ color: 'white' }}>
                <HomeIcon /> <span style={{ fontSize: '20px', marginLeft: '5px' }}>Home</span>
              </IconButton>
            </Link>
            <Link to="/orders" style={{ marginRight: "20px" }}>
              <IconButton aria-label="orders" sx={{ color: 'white' }}>
                <LocalMallIcon /> <span style={{ fontSize: '20px', marginLeft: '5px' }}>Orders</span>
              </IconButton>
            </Link>
            <Link to="/support" style={{ marginRight: "20px" }}>
              <IconButton aria-label="support" sx={{ color: 'white' }}>
                <SupportAgentIcon /> <span style={{ fontSize: '20px', marginLeft: '5px' }}>Support</span>
              </IconButton>
            </Link>
          </Box>

          {username ? (
            <MenuItem component={Link} to="/profile" sx={{ color: '#013567', marginLeft: 1, fontWeight: '700', fontSize: '20px', marginRight: '20px' }}>
              Hi, {username}
            </MenuItem>
          ) : (
            <Link to="/login" style={{ marginRight: "20px" }}>
              <IconButton edge="end" aria-label="account of current user" sx={{ color: 'white' }}>
                <AccountCircle /> <span style={{ fontSize: '20px', marginLeft: '5px' }}>Login/SignUp</span>
              </IconButton>
            </Link>
          )}

          <Link to="/cart">
            <IconButton aria-label="cart" sx={{ color: 'white', marginLeft: 1 }}>
              <Badge badgeContent={cartItemCount} color="error">
                <ShoppingCartIcon /> <span style={{ fontSize: '20px', marginLeft: '5px' }}>Cart</span>
              </Badge>
            </IconButton>
          </Link>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Toolbar />
      <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
      <Drawer anchor="top" open={searchOpen} onClose={handleSearchToggle}>
        <Box p={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <SearchContainer>
            <SearchInput
              placeholder="Search for medicines & wellness products..."
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setShowSuggestions(true);
              }}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              inputProps={{ 'aria-label': 'search' }}
            />
            <IconButton type="button" sx={IconButtonStyle} onClick={handleSearch}>
              {loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
            </IconButton>
          </SearchContainer>
        </Box>
      </Drawer>
    </>
  );
}

export default Navbar;
