import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CardMedia, CircularProgress, Grid, Snackbar, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';

const DetailPage = () => {
    const { medicineId } = useParams();
    const [medicine, setMedicine] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [inCart, setInCart] = useState(false);

    useEffect(() => {
        const fetchMedicineDetails = async () => {
            if (!medicineId) {
                setError("No medicine ID provided.");
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`https://namami-infotech.com/EvaraBackend/src/sku/get_sku.php?medicine_id=${medicineId}`);
                const data = await response.json();
                if (data.message === "Medicine details fetched successfully") {
                    setMedicine(data.medicine);
                } else {
                    setError("Failed to fetch medicine details.");
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchMedicineDetails();
    }, [medicineId]);

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const itemInCart = cart.find(item => item.id === parseInt(medicineId));

        if (itemInCart) {
            setInCart(true);
            setQuantity(itemInCart.quantity);
        } else {
            setInCart(false);
            setQuantity(1);
        }
    }, [medicineId]);

    const handleAddToCart = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const updatedCart = [...cart, { id: parseInt(medicineId), quantity }];
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setSnackbarMessage('Added to cart!');
        setSnackbarOpen(true);
        setInCart(true);
    };

    const handleQuantityChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 999) {
            setQuantity(value);
            updateCart(value);
        }
    };

    const incrementQuantity = () => {
        if (quantity < 999) {
            const newQuantity = quantity + 1;
            setQuantity(newQuantity);
            updateCart(newQuantity);
        }
    };

    const decrementQuantity = () => {
        if (quantity > 0) {
            const newQuantity = quantity - 1;
            if (newQuantity > 0) {
                setQuantity(newQuantity);
                updateCart(newQuantity);
            } else {
                // If quantity is decremented to 0, remove the item from the cart
                removeFromCart();
                setInCart(false);
                setQuantity(1); // Reset quantity for UI display
            }
        }
    };

    const updateCart = (newQuantity) => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const updatedCart = cart.map(item =>
            item.id === parseInt(medicineId) ? { ...item, quantity: newQuantity } : item
        );
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const removeFromCart = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const updatedCart = cart.filter(item => item.id !== parseInt(medicineId));
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ maxWidth: 1200, margin: 'auto', padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CardMedia
                        component="img"
                        height="400"
                        image={medicine.image_url}
                        alt={medicine.name}
                        sx={{ objectFit: 'contain', marginBottom: 2 }}
                    />
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">{medicine.name}</Typography>
                    <Typography variant="subtitle1" color="text.secondary">{medicine.company_name}</Typography>
                    <Typography variant="body1" sx={{ marginY: 1 }}>
                        MRP: ₹{medicine.mrp} | PTR: ₹{medicine.ptr} | Selling Price: ₹{medicine.selling_price}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Product Details:</strong> {medicine.product_details}
                    </Typography>
                    <Typography variant="body2">
                        <strong>Salts:</strong> {medicine.salts}
                    </Typography>

                    {inCart ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                            <Button 
                                variant="contained"
                                sx={{ minWidth: '40px', backgroundColor: 'teal', borderRadius: '15px', marginRight: '8px' }} 
                                onClick={decrementQuantity} 
                                disabled={quantity <= 0}
                            >
                                -
                            </Button>
                            <TextField
                                size="small"
                                value={quantity}
                                onChange={handleQuantityChange}
                                inputProps={{ min: 0, max: 999, style: { textAlign: 'center' } }}
                                sx={{ width: '60px', mx: 2 }}
                            />
                            <Button 
                                variant="contained"
                                sx={{ minWidth: '40px', backgroundColor: 'teal', borderRadius: '15px', marginLeft: '8px' }} 
                                onClick={incrementQuantity} 
                                disabled={quantity >= 999}
                            >
                                +
                            </Button>
                        </Box>
                    ) : (
                        <Button 
                            size="medium"
                            variant="contained"
                            sx={{ backgroundColor: 'teal', borderRadius: '15px', marginTop: 2 }} 
                            onClick={handleAddToCart}
                        >
                            Add to Cart
                        </Button>
                    )}
                </Grid>
            </Grid>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default DetailPage;
