import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, TextField, Button, Card, CardContent, Box } from '@mui/material';

const Support = () => {
    const [contactInfo, setContactInfo] = useState({ phone: '', email: '' });

    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const response = await fetch('https://namami-infotech.com/EvaraBackend/src/support/get_support.php');
                const data = await response.json();
                if (data.success && data.data.length > 0) {
                    const { phone_number, email } = data.data[0];
                    setContactInfo({ phone: phone_number, email });
                }
            } catch (error) {
                console.error('Error fetching contact info:', error);
            }
        };

        fetchContactInfo();
    }, []);

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{color:"orange"}} gutterBottom>
                Support & Contact Us
            </Typography>
            
            <Typography variant="body1" sx={{color:"teal"}} gutterBottom>
                If you have any questions or need assistance, please fill out the form below, or check our FAQs for quick answers.
            </Typography>
            
            <Grid container spacing={4}>
                {/* Contact Form */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" sx={{color:"orange"}} gutterBottom>
                                Contact Form
                            </Typography>
                            <form>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    label="Message"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    margin="normal"
                                    required
                                />
                                <Button variant="contained" type="submit" sx={{ backgroundColor: 'teal' }} fullWidth>
                                    Submit
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Contact Information */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" sx={{ mt: 4,color:'orange' }} gutterBottom>
                                Contact Information
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Phone: {contactInfo.phone || 'Loading...'}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Email: {contactInfo.email || 'Loading...'}
                            </Typography>
                            {/* Google Map */}
                            <Box sx={{ mt: 2 }}>
                                <iframe
                                    title="Our Location"
                                    src='https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3499.5258527973187!2d77.44695667550343!3d28.70382687562583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQyJzEzLjgiTiA3N8KwMjYnNTguMyJF!5e0!3m2!1sen!2sin!4v1729408130399!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
                                    width="100%"
                                    height="240"
                                    style={{ border: 0 }}
                                    allowFullScreen="true"
                                    loading="lazy"
                                ></iframe>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Support;
