import React, { useEffect, useState } from 'react';
import { Card as MuiCard, CardMedia, CardContent, Typography, Button, Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomCard = ({ image, title, description, mrp, ptr, offer, medicineId }) => {
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    const [inCart, setInCart] = useState(false);

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const itemInCart = cart.find(item => item.id === parseInt(medicineId));

        if (itemInCart) {
            setInCart(true);
            setQuantity(itemInCart.quantity);
        } else {
            setInCart(false);
            setQuantity(1);
        }
    }, [medicineId]);

    const handleCardClick = () => {
        navigate(`/product/${medicineId}`);
    };

    const handleAddToCart = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const updatedCart = [...cart, { id: parseInt(medicineId), quantity }];
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        setInCart(true);
    };

    const handleQuantityChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value >= 1 && value <= 999) {
            setQuantity(value);
            updateCart(value);
        }
    };

    const incrementQuantity = () => {
        if (quantity < 999) {
            const newQuantity = quantity + 1;
            setQuantity(newQuantity);
            updateCart(newQuantity);
        }
    };

    const decrementQuantity = () => {
        if (quantity > 0) {
            const newQuantity = quantity - 1;
            if (newQuantity > 0) {
                setQuantity(newQuantity);
                updateCart(newQuantity);
            } else {
                // If quantity is decremented to 0, remove the item from the cart
                removeFromCart();
                setInCart(false);
                setQuantity(1); // Reset quantity for UI display
            }
        }
    };

    const updateCart = (newQuantity) => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const updatedCart = cart.map(item =>
            item.id === parseInt(medicineId) ? { ...item, quantity: newQuantity } : item
        );
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const removeFromCart = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const updatedCart = cart.filter(item => item.id !== parseInt(medicineId));
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    return (
        <MuiCard sx={{ position: 'relative', maxWidth: 300, margin: 'auto', boxShadow: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardMedia
                component="img"
                height="200"
                image={image}
                alt={title}
                sx={{ objectFit: 'contain', padding: '5px', cursor: 'pointer' }}
                onClick={handleCardClick}
            />
            {offer && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        backgroundColor: 'green',
                        color: 'white',
                        padding: '8px 12px',
                        borderRadius: '8px',
                        fontWeight: 'bold',
                    }}
                >
                    {offer}
                </Box>
            )}
            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography gutterBottom variant="body6" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    MRP: ₹{mrp}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    PTR: ₹{ptr}
                </Typography>
            </CardContent>
            <Box sx={{ textAlign: 'center', padding: '10px' }}>
                {!inCart ? (
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ backgroundColor: 'teal', borderRadius: '15px' }}
                        onClick={handleAddToCart}
                    >
                        Add to Cart
                    </Button>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ minWidth: '40px', backgroundColor: 'teal', borderRadius: '15px', marginRight: '8px' }}
                            onClick={decrementQuantity}
                            disabled={quantity <= 0}
                        >
                            -
                        </Button>
                        <TextField
                            size="small"
                            value={quantity}
                            onChange={handleQuantityChange}
                            inputProps={{ min: 1, max: 999, style: { textAlign: 'center' } }}
                            sx={{ width: '60px' }}
                        />
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ minWidth: '40px', backgroundColor: 'teal', borderRadius: '15px', marginLeft: '8px' }}
                            onClick={incrementQuantity}
                            disabled={quantity >= 999}
                        >
                            +
                        </Button>
                    </Box>
                )}
            </Box>
        </MuiCard>
    );
};

export default CustomCard;
